import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './HomePage.css';
import FlipCard from '../flipcard/FlipCard';

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;

    document.body.style.backgroundColor = '#a5ceff';

    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);

  const handleButtonClick = () => {
    setTimeout(() => {
      navigate('/traducir');
    }, 50);
  };

  return (
    <div id="homepage">
      <div className="section one">
        <div className="level-info-area homepage title">
          <h1>
            Bienvenido a
            <span id="spanglish-homepage">
              <b> </b>
              Spanglish.lat
            </span>
          </h1>
          <h2 id="sub-heading">
            Con nuestro
            <b> </b>
            <Link id="traductor-link" to="/traducir">Traductor</Link>
            <b> </b>
            de pronunciación, puedes escribir cualquier texto en inglés
            y ver cómo se pronuncia usando letras y sonidos en español.
            <br />
          </h2>
        </div>
      </div>
      <br />
      <br />
      <div className="section three">
        <div className="level-info-area homepage">
          <h3>
            Para ver cómo funciona, observa las ocho tarjetas que se muestran a continuación.
            <br />
            <br />
            Lee cada tarjeta como si estuvieras leyendo cualquier palabra en español.
            Luego, haz clic en las tarjetas y verás lo que acabas de pronunciar en inglés!
          </h3>
        </div>
        <div id="third-section-cards">
          <FlipCard frontText="jelóu" backText="hello" backText2="(hola)" imageSrc="/static/icons/wave.webp" />
          <FlipCard frontText="gudbái" backText="goodbye" backText2="(adiós)" imageSrc="/static/icons/wave2.webp" />
          <FlipCard frontText="kof" backText="cough" backText2="(tos)" imageSrc="/static/icons/cough.webp" />
          <FlipCard frontText="iélou" backText="yellow" backText2="(amarillo)" imageSrc="/static/icons/yellow.webp" />
          <FlipCard frontText="wénsdei" backText="Wednesday" backText2="(miércoles)" imageSrc="/static/icons/wednesday.webp" />
          <FlipCard frontText="kiu" backText="queue" backText2="(fila)" imageSrc="/static/icons/queue.webp" />
          <FlipCard frontText="bukéi" backText="bouquet" backText2="(ramo)" imageSrc="/static/icons/wedding.webp" />
          <FlipCard frontText="risít" backText="receipt" backText2="(recibo)" imageSrc="/static/icons/receipt.webp" />
        </div>
      </div>
      <div id="example-gif-div">
        <p>Mira este ejemplo del traductor:</p>
        <img src="/static/example_gif.gif" alt="Ejemplo del traductor" id="example-gif" />
      </div>
      <div className="section four">
        <div className="level-info-area homepage">
          <h4>
            Es para todos! Nuestro traductor esta diseñado tanto para principiantes,
            aquellos que no han estudiado inglés, como para los que ya están familiarizados
            con sus sonidos y pronunciaciones.
            <br />
            <br />
            Encontrarás 8 niveles de dificultad, y para entender como funcionan
            ve a la pestaña &apos;info&apos; del traductor.
          </h4>
        </div>
      </div>
      <div className="section five">
        <button
          type="button"
          className="custom-button homepage"
          onClick={handleButtonClick}
        >
          <h3>
            Inténtalo ahora - haz clic aquí para empezar a hablar en inglés.
            <br />
            <br />
            <span id="ir-al-traductor">
              <u>IR AL TRADUCTOR</u>
            </span>
          </h3>
        </button>
      </div>
    </div>
  );
}

export default HomePage;
