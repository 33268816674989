import React from 'react';

function TabInfo0() {
  return (
    <div className="info-content">
      {/* <p>
        <i>
          (Actualmente estamos trabajando en otras versiones para el español
          rioplatense y el español ibérico. ¡Vienen pronto!)
        </i>
      </p>
      <hr /> */}
      <h2>Introducción</h2>
      <p>
        Cuando ingreses texto en inglés en cualquier nivel,
        la guía de pronunciación que aparece abajo solo incluirá
        sonidos que existen en español. Esto significa que puedes leer texto en
        inglés en voz alta incluso si nunca has tenido una lección de inglés.
        <br />
      </p>
      <hr />
      <div className="star-heading-container">
        <img src="/static/star.webp" alt="star icon" id="star-icon" />
        <h2>¿Cómo funcionan los Niveles?</h2>
      </div>
      <div id="p-below-h2">
        Cada nivel introduce un nuevo sonido que se incluirá en la traducción de
        pronunciación. Así que si ya sabes un poco de inglés, ¡prueba los otros
        niveles!
        <br />
        <br />
        Veamos un ejemplo de una frase en inglés y veamos cómo cambia en los primeros 4 niveles:
        <br />
        <br />
        <p id="level-examples">
          <span id="example-sentence">
            &quot;His shoes are very dirty.&quot;
          </span>
          <br />
          <br />
          <b>Nivel 1, la salida:</b>
          <br />
          jis chus ar féri dérti.
          <br />
          <br />
          <b>Nivel 2 introduce el sonido Z:</b>
          <br />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          ji<mark className="highlight">z</mark> chu<mark className="highlight">z</mark> ar féri dérti.
          <br />
          <br />
          <b>Nivel 3 introduce el sonido SH:</b>
          <br />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          jiz <mark className="highlight">sh</mark>uz ar féri dérti.
          <br />
          <br />
          <b>Nivel 4 introduce el sonido V:</b>
          <br />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          jiz shuz ar <mark className="highlight">v</mark>éri dérti.
          <br />
          <br />
          <b>Niveles 5, 6a, 6b, 7...</b>
          <br />
          ¡Inténtalos tú!
        </p>
        Lea la descripción de cada nivel para más información.
        <br />
        <br />
        ¡Haz clic en el botón de Nivel 1 arriba para empezar!
      </div>
      <hr />
      <h2>¿Y si ya hablo inglés?</h2>
      <p>
        Intenta este reto: pronuncia esta oración en voz alta:
        <br />
        <br />
        <i>
          On Wednesday, aboard the yacht, I found a receipt for jewellery while
          dining on a vegetable salad with quinoa and salmon.
        </i>
        <br />
        <br />
        ¿Cómo te fue? ¡Ingresa la frase en el traductor y descúbrelo!
      </p>
    </div>
  );
}

export default TabInfo0;
