import React, { useState, useEffect } from 'react';
import './TranslatePage.css';
import TranslationComponent from './TranslationComponent';
import TabInfo0 from '../tab_contents/TabInfo0';
import { THEMES } from '../../Constants';

const LEVEL_BUTTONS = [
  'Info',
  'Nivel 1',
  'Nivel 2',
  'Nivel 3',
  'Nivel 4',
  'Nivel 5',
  'Nivel 6a',
  'Nivel 6b',
  'Nivel 7',
];
const LEVEL_BUTTON_SYMBOLS = {
  Info: 'ⓘ',
  'Nivel 1': 'w',
  'Nivel 2': 'z',
  'Nivel 3': 'sh',
  'Nivel 4': 'v',
  'Nivel 5': 'ɣ',
  'Nivel 6a': 'th',
  'Nivel 6b': 'ə',
  'Nivel 7': 'todos',
};

function TranslatePage() {
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [textInput, setTextInput] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 480);
  const [themeIndex, setThemeIndex] = useState(0); // New state for theme index

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Apply the default or current theme class to the body
    const themeClass = THEMES[themeIndex].class;
    document.body.className = themeClass;
    document.body.style.backgroundColor = THEMES[themeIndex].backgroundColor;
    // Update nav link colors and any other elements if necessary
  }, [themeIndex]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 480);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to cycle through the themes
  const cycleThemes = () => {
    setThemeIndex((prevIndex) => (prevIndex + 1) % THEMES.length);
  };

  const currentImage = isSmallScreen
    ? THEMES[themeIndex].smallScreenImageMap
    : THEMES[themeIndex].imageMap;

  return (
    <>
      <div className="site-logo-container">
        <img src={currentImage} alt="Spanglish.lat" id="title-img" />
      </div>
      <h1 className="page-title" hidden>
        Spanglish.lat traductor inglés a pronunciación fonética de español
      </h1>
      <div className="translate-wrapper">
        <div className="levelButtons-wrapper">
          <div className="levelButtons-list">
            {LEVEL_BUTTONS.map((name, index) => (
              <button
                type="button"
                key={name}
                onClick={() => setSelectedButtonIndex(index)}
                className={`custom-button ${
                  selectedButtonIndex === index ? 'active' : ''
                }`}
              >
                {name}
                <div className="button-symbol">
                  {LEVEL_BUTTON_SYMBOLS[name]}
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="level-content">
          {selectedButtonIndex === 0 ? (
            <div className="level-info-area">
              <TabInfo0 />
            </div>
          ) : (
            <TranslationComponent
              activeTabIndex={selectedButtonIndex}
              textInput={textInput}
              setTextInput={setTextInput}
              cycleThemes={cycleThemes}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TranslatePage;
