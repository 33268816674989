import React from 'react';
import { Helmet } from 'react-helmet-async';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import TranslatePage from './components/translatepage/TranslatePage';
import HomePage from './components/homepage/HomePage';
import ContactForm from './components/ContactForm';
import BodyClassUpdater from './components/BodyClassUpdater';
import Navbar from './components/Navbar';

const csp = `
  default-src 'self';
  script-src 'self'  https://www.google.com/recaptcha https://www.gstatic.com;
  style-src 'self' 'unsafe-inline';
  img-src 'self' data:;
  connect-src 'self' https://spanglish.lat https://www.spanglish.lat https://www.google-analytics.com;
  font-src 'self';
  object-src 'none';
  media-src 'self';
  frame-src 'self' https://www.google.com/;
`;

function App() {
  return (
    <Router>
      <Helmet>
        <meta httpEquiv="Content-Security-Policy" content={csp} />
      </Helmet>
      <BodyClassUpdater />
      <Navbar />
      <div className="main-content">
        <Routes>
          <Route path="/traducir" element={<TranslatePage />} />
          <Route path="/contacto" element={<ContactForm />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </div>
      <footer className="app-footer">
        <a href="/contacto"><u>Contacto</u></a>
        <p>
          Muchas gracias al Speech Group de la Universidad Carnegie Mellon. Debido a su
          trabajo hemos podido crear este sitio.
        </p>
      </footer>
    </Router>
  );
}

export default App;
