import React from 'react';
import {
  nivel1desc, nivel2desc, nivel3desc, nivel4desc,
} from './Descriptions';

function TabInfo4() {
  return (
    <div className="tab-info">
      <h3>Nivel 4</h3>
      <p>
        El V en inglés y el V en español se pronuncian de manera diferente. Este
        nivel introduce la V inglesa en las traducciones de pronunciación. Ahora
        es importante recordar que siempre que veas una V, no debes pronunciarla
        como lo haces en español. En su lugar, cuando veasuna V, así es como debes decirlo:
      </p>
      <p className="current-level">{ nivel4desc }</p>
      <hr />
      Este nivel también incluye los cambios de los niveles anteriores:
      <p>{ nivel3desc }</p>
      <p>{ nivel2desc }</p>
      <p>{ nivel1desc }</p>
    </div>
  );
}

export default TabInfo4;
