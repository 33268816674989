import React from 'react';
import {
  nivel1desc, nivel2desc, nivel3desc, nivel4desc, nivel5desc,
} from './Descriptions';

function TabInfo5() {
  return (
    <div className="tab-info">
      <h3>Nivel 5</h3>
      <p>
        Este nivel introduce el sonido J inglés en la salida de pronunciación. El
        J en inglés y el J en español no se parecen en absoluto. Por lo tanto,
        para representar el sonido J inglés, hemos utilizado el símbolo ɣ.
        Continúa pronunciando la letra j como lo harías en español. Pero ahora,
        cada vez que veas el símbolo ɣ, esto es lo que significa:
      </p>
      <p className="current-level">{ nivel5desc }</p>
      <hr />
      Este nivel también incluye los cambios de los niveles anteriores:
      <p>{ nivel4desc }</p>
      <p>{ nivel3desc }</p>
      <p>{ nivel2desc }</p>
      <p>{ nivel1desc }</p>
    </div>
  );
}

export default TabInfo5;
