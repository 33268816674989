import React from 'react';
import {
  nivel1desc, nivel2desc, nivel3desc, nivel4desc, nivel5desc, nivel7desc,
} from './Descriptions';

function TabInfo7() {
  return (
    <div className="tab-info">
      <h3>Nivel 6b</h3>
      <p>
        Este nivel es posiblemente el más difícil. Introduce el sonido schwa - el
        sonido más común en inglés. Sin embargo, raramente se enseña este sonido en
        clases. Cuando veas el símbolo
        <b> </b>
        <b>ə</b>
        <b> </b>
        que parece una letra e al revés, esto es
        lo que significa:
      </p>
      <p className="current-level">{ nivel7desc }</p>
      <hr />
      Este nivel también incluye los cambios de los niveles anteriores (excluyendo Nivel 6a):
      <p>{ nivel5desc }</p>
      <p>{ nivel4desc }</p>
      <p>{ nivel3desc }</p>
      <p>{ nivel2desc }</p>
      <p>{ nivel1desc }</p>
    </div>
  );
}

export default TabInfo7;
