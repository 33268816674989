import React from 'react';

export const nivel1desc = (
  <>
    <span className="letter-example">w</span>
    <b> </b>
    - El sonido en agua (agwa), cuenta (cwenta), y fuente (fwente).
    <br />
  </>
);

export const nivel3desc = (
  <>
    <span className="letter-example">sh</span>
    <b> </b>
    - El sonido en &quot;yo me llamo&quot; en ciertas regiones de
    Argentina y Uruguay, o el sonido que haces cuando pides silencio.
    <b> </b>
    <a
      href="https://youtu.be/iORIPzWXMFE?si=gccRVYFbyx3GpbkT"
      target="_blank"
      rel="noopener noreferrer"
    >
      VIDEO
    </a>
    .
    <br />
  </>
);

export const nivel2desc = (
  <>
    <span className="letter-example">z</span>
    <b> </b>
    - Exactamente la misma posición de boca que la letra S, pero
    haciendo sonido con tu voz. Sonarás como una mosca o abeja zumbando.
    <b> </b>
    <a
      href="https://youtu.be/6oinexBXGDc?si=Ua1jXvGi8q0C3Qkv"
      target="_blank"
      rel="noopener noreferrer"
    >
      VIDEO
    </a>
    .
    <br />
  </>
);

export const nivel4desc = (
  <>
    <span className="letter-example">v</span>
    <b> </b>
    - Exactamente la misma posición de boca que la letra F, pero
    haciendo sonido con tu voz. Sonarás como un celular vibrando.
    <b> </b>
    <a
      href="https://youtu.be/YGK2gEH-QVo?si=WVUx7TKoEVOfdw3q"
      target="_blank"
      rel="noopener noreferrer"
    >
      VIDEO
    </a>
    .
    <br />
  </>
);

export const nivel5desc = (
  <>
    <span className="letter-example">ɣ</span>
    <b> </b>
    - Representa la letra J en inglés, y es parecido al primer sonido en los
    nombres Yeison y Yolanda, pero con un toque del sonido CH.
    <b> </b>
    <a
      href="https://youtu.be/MyksCMc420w?si=MWBN0H7uQw-42MEJ"
      target="_blank"
      rel="noopener noreferrer"
    >
      VIDEO
    </a>
    .
    <br />
  </>
);

export const nivel6desc = (
  <>
    <span className="letter-example">th</span>
    <b> </b>
    - El sonido en &quot;cerveza&quot; si pides una en España
    <i> (thervetha)</i>
    . También es el último sonido del nombre Smith.
    <b> </b>
    <a
      href="https://youtu.be/fqn3EHVZv1Q?si=0zZfPFukIsemr8V9"
      target="_blank"
      rel="noopener noreferrer"
    >
      VIDEO
    </a>
    .
    <br />
  </>
);

export const nivel7desc = (
  <>
    <span className="letter-example schwa">ə</span>
    <b> </b>
    - Es imposible describir este sonido por escrito, así que
    <b> </b>
    <a
      href="https://youtu.be/cOlz-7zvxbE?si=9wNGVHZto7QNUIIs"
      target="_blank"
      rel="noopener noreferrer"
    >
      aquí tienes un excelente video al respecto
    </a>
    .
    <br />
  </>
);

export const recuerda = '(Si cambias de nivel tienes que volver a hacer clic en Enviar para aplicarlo)';

export const laR = (
  <>
    La R debe ser pronunciada como
    <b> </b>
    <a
      href="https://youtu.be/8jtK_7oa22Q?si=f6-KL1exQA6EfIjY"
      target="_blank"
      rel="noopener noreferrer"
    >
      una R inglésa
    </a>
    ,
    <b> </b>
    pero si no sabes cómo pronunciarla, dila como te salga naturalmente.
    <br />
  </>
);
