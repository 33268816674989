import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function BodyClassUpdater() {
  const location = useLocation();

  useEffect(() => {
    // Remove previous body classes before adding new ones
    document.body.classList.remove(
      'traducir-style',
      'contacto-style',
      'default-style',
    );

    // When location changes, update body class/style
    if (location.pathname === '/traducir') {
      document.body.classList.add('traducir-style');
    } else if (location.pathname === '/contacto') {
      document.body.classList.add('contacto-style');
    } else {
      document.body.classList.add('default-style');
    }

    // Cleanup function to remove classes when the component is unmounted
    return () => {
      document.body.classList.remove(
        'traducir-style',
        'contacto-style',
        'default-style',
      );
    };
  }, [location]);

  return null; // This component doesn't render anything to the DOM
}

export default BodyClassUpdater;
