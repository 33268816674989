import React from 'react';
import { NavLink } from 'react-router-dom';

function NavbarComponent() {
  return (
    <div className="navigation">
      <NavLink
        to="/"
        className="navlink"
        id="navlink-inicio"
        role="button"
      >
        Inicio
      </NavLink>
      <NavLink
        to="/traducir"
        className="navlink"
        id="navlink-traductor"
        role="button"
      >
        Traductor
      </NavLink>
      <NavLink
        to="/contacto"
        className="navlink"
        id="navlink-contacto"
        role="button"
      >
        Contacto
      </NavLink>
    </div>
  );
}

export default NavbarComponent;
