import React from 'react';
import {
  nivel1desc, nivel2desc, nivel3desc, nivel4desc, nivel5desc, nivel6desc, nivel7desc,
} from './Descriptions';

function TabInfo8() {
  return (
    <div className="tab-info">
      <h3>Nivel 7</h3>
      <p>
        <b>
          El nivel final. Aquí, todos los sonidos de los niveles anteriores
          están incluidos en la pronunciación. ¡Buena suerte!
        </b>
      </p>
      <hr />
      Los cambios de los niveles anteriores:
      <p>{ nivel7desc }</p>
      <p>{ nivel6desc }</p>
      <p>{ nivel5desc }</p>
      <p>{ nivel4desc }</p>
      <p>{ nivel3desc }</p>
      <p>{ nivel2desc }</p>
      <p>{ nivel1desc }</p>
    </div>
  );
}

export default TabInfo8;
