import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FlipCard.css';

function FlipCard({
  frontText,
  backText,
  backText2,
  imageSrc,
}) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsFlipped(!isFlipped);
    }
  };

  return (
    <div
      className="card"
      onClick={handleCardClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
    >
      <div className={`card-inner ${isFlipped ? 'flipped' : ''}`}>
        <div className="card-front">
          <p>{frontText}</p>
        </div>
        <div className="card-back">
          <div className="text-group">
            <p>{backText}</p>
            <p>{backText2}</p>
          </div>
          {imageSrc && <img src={imageSrc} alt="icon" />}
        </div>
      </div>
    </div>
  );
}

FlipCard.propTypes = {
  frontText: PropTypes.string,
  backText: PropTypes.string,
  backText2: PropTypes.string,
  imageSrc: PropTypes.string,
};

FlipCard.defaultProps = {
  frontText: '',
  backText: '',
  backText2: '',
  imageSrc: '',
};

export default FlipCard;
