const API_URL = 'https://spanglish.lat/api';
const ERROR_MESSAGE = 'Error inesperado. Por favor, actualiza la página e intenta de nuevo.';
const THEMES = [
  {
    class: 'theme-blue-orange',
    backgroundColor: '#a5ceff',
    navLinkColor: 'black',
    imageMap: '/static/spanglish_title/spanglish-blue-orange2 darkdot.webp',
    smallScreenImageMap:
      '/static/spanglish_title/spanglish-mobile-blue-orange2.webp',
  },
  {
    class: 'theme-lightgreen-salmon',
    backgroundColor: '#ccffe6',
    navLinkColor: 'black',
    imageMap:
      '/static/spanglish_title/spanglish-lightgreen-salmon2 darkdot.webp',
    smallScreenImageMap:
      '/static/spanglish_title/spanglish-mobile-lightgreen-salmon2.webp',
  },
  {
    class: 'theme-cyan-pink',
    backgroundColor: '#9BF6FF',
    navLinkColor: 'black',
    imageMap: '/static/spanglish_title/spanglish-cyan-pink2 darkdot.webp',
    smallScreenImageMap:
      '/static/spanglish_title/spanglish-mobile-cyan-pink2.webp',
  },
  {
    class: 'theme-cyan-sand',
    backgroundColor: '#9bf7f6',
    navLinkColor: 'black',
    imageMap: '/static/spanglish_title/spanglish-cyan-sand2 darkdot.webp',
    smallScreenImageMap:
      '/static/spanglish_title/spanglish-mobile-cyan-sand2.webp',
  },
];

export { API_URL, ERROR_MESSAGE, THEMES };
