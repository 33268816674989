import React from 'react';
import {
  nivel1desc, nivel2desc, nivel3desc, nivel4desc, nivel5desc, nivel6desc,
} from './Descriptions';

function TabInfo6() {
  return (
    <div className="tab-info">
      <h3>Nivel 6a</h3>
      <p>
        El sonido TH del inglés no existe en la gran mayoría de los dialectos
        españoles, pero es común en inglés. Los niveles anteriores representaban
        este sonido inglés como una T o una D. Este nivel introduce el TH en la
        traducción de pronunciación. Cuando veas un
        <b> </b>
        <u>th</u>
        , esto es lo que significa:
      </p>
      <p className="current-level">{ nivel6desc }</p>
      <hr />
      Este nivel también incluye los cambios de los niveles anteriores:
      <p>{ nivel5desc }</p>
      <p>{ nivel4desc }</p>
      <p>{ nivel3desc }</p>
      <p>{ nivel2desc }</p>
      <p>{ nivel1desc }</p>
    </div>
  );
}

export default TabInfo6;
