import React from 'react';
import {
  nivel1desc, nivel2desc,
} from './Descriptions';

function TabInfo2() {
  return (
    <div className="tab-info">
      <h3>Nivel 2</h3>
      <p>
        En inglés, hay una diferencia entre el sonido S y el sonido Z.
        Aquí, en el
        <b> Nivel 2</b>
        , se introduce el sonido Z inglés. Esto significa que
        la salida ahora puede incluir la letra Z para representar el sonido
        Z. Por lo tanto, cuando veas la letra Z, esto es lo que significa:
      </p>
      <p className="current-level">{ nivel2desc }</p>
      <hr />
      Este nivel también incluye el cambio del nivel anterior:
      <p>{ nivel1desc }</p>
    </div>
  );
}

export default TabInfo2;
