import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { throttle } from 'lodash';
import PropTypes from 'prop-types';
import { API_URL, ERROR_MESSAGE } from '../../Constants';
import saveAsImage from '../../utils/pdfAndImageUtils';
import './TranslatePage.css';
import {
  TabInfo1,
  TabInfo2,
  TabInfo3,
  TabInfo4,
  TabInfo5,
  TabInfo6,
  TabInfo7,
  TabInfo8,
} from '../tab_contents/TabInfoExports';
import '../../buttons/custom-button.css';

const generateID = () => Math.random().toString(36).substr(2, 9);

function TranslationComponent({
  activeTabIndex,
  textInput,
  setTextInput,
  cycleThemes,
}) {
  const [error, setError] = useState(null);
  const [translationResponse, setTranslationResponse] = useState({
    englishWords: [],
    spanishWords: [],
  });
  const outputRef = useRef(null);
  const [fontSize, setFontSize] = useState(16);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const TAB_INFO_CONTENT = [
    <TabInfo1 />,
    <TabInfo2 />,
    <TabInfo3 />,
    <TabInfo4 />,
    <TabInfo5 />,
    <TabInfo6 />,
    <TabInfo7 />,
    <TabInfo8 />,
  ];

  const getSelectedButtonsForTabIndex = (tabIndex) => {
    const defaultSelectedButtons = {
      isLevel1W: false,
      isLevel2Z: false,
      isLevel3Sh: false,
      isLevel4V: false,
      isLevel5J: false,
      isLevel6Th: false,
      isLevel7Schwa: false,
      isLevel8All: false,
      isBritish: false,
      isRioplatense: false,
      isFromSpain: false,
    };

    switch (tabIndex) {
      case 1:
        return { ...defaultSelectedButtons, isLevel1W: true };
      case 2:
        return { ...defaultSelectedButtons, isLevel2Z: true };
      case 3:
        return { ...defaultSelectedButtons, isLevel3Sh: true };
      case 4:
        return { ...defaultSelectedButtons, isLevel4V: true };
      case 5:
        return { ...defaultSelectedButtons, isLevel5J: true };
      case 6:
        return { ...defaultSelectedButtons, isLevel6Th: true };
      case 7:
        return { ...defaultSelectedButtons, isLevel7Schwa: true };
      case 8:
        return { ...defaultSelectedButtons, isLevel8All: true };
      default:
        return defaultSelectedButtons;
    }
  };

  const handleTranslation = throttle(async (scrollToOutput = true) => {
    try {
      const currentSelectedButtons = getSelectedButtonsForTabIndex(activeTabIndex);
      const translateRequest = {
        selectedButtons: currentSelectedButtons,
        text: textInput,
      };
      const response = await axios.post(
        `${API_URL}/traducir`,
        translateRequest,
      );

      const transformedEnglishWords = response.data.englishWords.map(
        (word) => ({
          id: generateID(),
          word,
        }),
      );

      setTranslationResponse({
        englishWords: transformedEnglishWords,
        spanishWords: response.data.spanishWords,
      });

      setError(null);
    } catch (catchError) {
      if (catchError.response) {
        setError(catchError.response.data.message || ERROR_MESSAGE);
      } else if (catchError.request) {
        setError(ERROR_MESSAGE);
      } else {
        setError(ERROR_MESSAGE);
      }
    } finally {
      setIsLoading(false);
      if (scrollToOutput) {
        outputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, 1000);

  const saveAsImageThrottled = throttle((e) => {
    e.preventDefault();
    setIsLoading(true);
    let count = 0;
    const intervalId = setInterval(() => {
      count += 1;
      setLoadingText('.'.repeat(count % 4));
    }, 200);

    saveAsImage(
      outputRef,
      () => {
        setIsLoading(false);
        clearInterval(intervalId);
      },
      setLoadingText,
    );
  }, 5000);

  useEffect(() => {
    if (textInput && textInput.length > 0) {
      handleTranslation(false);
    }
  }, [activeTabIndex, textInput]);

  const handleFontSizeChange = (adjustment) => {
    setFontSize((prevSize) => Math.max(10, prevSize + adjustment));
  };

  return (
    <div className="translation-component">
      <div className="row">
        <div className="level-info-area">
          {TAB_INFO_CONTENT[activeTabIndex - 1]}
        </div>
        <div
          className={`char-count ${
            textInput.length >= 2000 ? 'max-reached' : ''
          }`}
        >
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          {textInput.length} / 2000
        </div>
        <div className="text-input-area">
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Prevent the default form submit action
              handleTranslation();
            }}
          >
            <label htmlFor="textArea">
              <span className="visually-hidden">
                Ingresa texto inglés aquí...
              </span>
              <textarea
                id="textArea"
                value={textInput}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setTextInput(
                    newValue.length > 2000 ? newValue.slice(0, 2000) : newValue,
                  );
                }}
                rows="6"
                className="translation-textarea"
                placeholder="Ingresar el texto a traducir"
              />
            </label>
            <div className="buttons-container">
              <button
                type="button"
                className="custom-button mystery"
                onClick={cycleThemes}
                aria-label="Cambiar Tema"
              >
                ?
              </button>
              <button
                className="custom-button thin"
                type="button"
                onClick={() => setTextInput('')}
                aria-label="Limpiar"
                disabled={isLoading}
              >
                {isLoading ? loadingText : 'Limpiar'}
              </button>
              <button
                className="custom-button thin"
                type="submit"
                onClick={handleTranslation}
                aria-label="Traducir"
                disabled={isLoading}
              >
                {isLoading ? loadingText : 'Enviar'}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="output-text">
        {/* Only render the button and word grid if there's output text */}
        {translationResponse.englishWords.length > 0 && (
          <>
            <div className="controls-container">
              <div className="save-img-btn">
                <button
                  className="custom-button thin"
                  type="button"
                  onClick={saveAsImageThrottled}
                  disabled={isLoading}
                >
                  {isLoading ? loadingText : 'Guardar Como Imagen'}
                </button>
              </div>

              <div className="font-size-adjustment">
                <button
                  type="button"
                  className="custom-button font-change"
                  onClick={() => handleFontSizeChange(-1)}
                >
                  -
                </button>
                <button
                  type="button"
                  className="custom-button font-change"
                  onClick={() => handleFontSizeChange(1)}
                >
                  +
                </button>
              </div>
            </div>

            <div
              ref={outputRef}
              className="word-grid word-grid-container"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                fontSize: `${fontSize}px`,
              }}
            >
              {translationResponse.englishWords.map((wordObj, index) => {
                if (wordObj.word === '<br/>') {
                  return <div key={wordObj.id} style={{ width: '100%' }} />;
                }

                return (
                  <div
                    key={wordObj.id}
                    className="word-pair"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginRight: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <span
                      className="english-word"
                      style={{ marginBottom: '2px' }}
                    >
                      <b>{wordObj.word}</b>
                    </span>
                    <span
                      className="spanish-word"
                      dangerouslySetInnerHTML={{
                        __html: translationResponse.spanishWords[index],
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="red-blue word-grid-container">
              <p>
                Palabra
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <span id="azul"> azul</span>: o es un heterónimo (
                <a
                  href="https://youtu.be/ZvIikVln1l4?si=Gez575FF1hunZOU1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  video
                </a>
                ) o se pronuncia diferente en inglés británico/estadounidense.
                <br />
                <br />
                Palabra
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <span id="roja"> roja</span>: no se encontró en nuestro
                diccionario (no significa que la palabra esté incorrecta).
              </p>
            </div>
          </>
        )}
      </div>

      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
    </div>
  );
}

TranslationComponent.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  textInput: PropTypes.string.isRequired,
  setTextInput: PropTypes.func.isRequired,
  cycleThemes: PropTypes.func.isRequired,
};

export default TranslationComponent;
