import domtoimage from 'dom-to-image';
import { ERROR_MESSAGE } from '../Constants';

const saveAsImage = (outputRef, setIsLoading, setLoadingText, setError) => {
  setIsLoading(true);
  let count = 0;
  const intervalId = setInterval(() => {
    count += 1;
    setLoadingText('.'.repeat(count % 4));
  }, 500);

  const { current: outputElement } = outputRef;
  if (!outputElement) {
    setError(ERROR_MESSAGE);
    setIsLoading(false);
    clearInterval(intervalId);
    return;
  }

  domtoimage
    .toPng(outputElement, {
      bgcolor: 'white',
    })
    .then((dataUrl) => {
      const link = document.createElement('a');
      link.download = 'spanglish-lat-texto.png';
      link.href = dataUrl;
      link.click();
      setIsLoading(false);
      clearInterval(intervalId);
    })
    .catch((error) => {
      setError(`Error al guardar la imagen: ${error.message}`);
      setIsLoading(false);
      clearInterval(intervalId);
    });
};

export default saveAsImage;
