import React, { useState } from 'react';
import styled from '@emotion/styled';
import { throttle } from 'lodash';
// import ReCAPTCHA from 'react-google-recaptcha';
import { API_URL } from '../Constants';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 8px 8px 0 0 #000000;
  background-color: #f9f9f9;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');
  // const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleRecaptchaChange = (value) => {
  //   setRecaptchaValue(value);
  // };

  const handleSubmit = throttle(async (e) => {
    e.preventDefault();

    // if (!recaptchaValue) {
    //   setStatusMessage("Please verify you're not a robot.");
    //   return;
    // }

    setStatusMessage('Enviando...');
    try {
      const response = await fetch(`${API_URL}/enviar-correo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
        });
        setStatusMessage('¡Correo enviado con éxito!');
      } else {
        setStatusMessage('No se pudo enviar el correo. Por favor, envíalo a spanglish.lat@gmail.com');
      }
    } catch (error) {
      setStatusMessage('No se pudo enviar el correo. Por favor, envíalo a spanglish.lat@gmail.com');
    }
  }, 2000);

  return (
    <>
      <h1 id="contact-title">Contáctame</h1>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Tu Nombre"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Tu Correo Electrónico"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <Input
          type="text"
          name="subject"
          placeholder="Asunto"
          value={formData.subject}
          onChange={handleChange}
          required
        />
        <TextArea
          name="message"
          placeholder="Tu Mensaje"
          rows="6"
          value={formData.message}
          onChange={handleChange}
          required
        />

        {/* <ReCAPTCHA
          sitekey="6LdMHE8oAAAAAALEsxnIR-5rlhbQOm6oeVPGYDbn" // TODO set up server-side.
          onChange={handleRecaptchaChange}
        /> */}

        <Button type="submit">Enviar Mensaje</Button>
        {statusMessage && <p>{statusMessage}</p>}
      </Form>
    </>
  );
}

export default ContactForm;
