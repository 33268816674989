import React from 'react';
import { nivel1desc } from './Descriptions';

function TabInfo1() {
  return (
    <div className="tab-info">
      <h3>Nivel 1</h3>
      <p>
        Está es el primer nivel. Al introducir texto en inglés
        verás una guía de pronunciación usando sonidos familiares en español. Simplemente,
        sigue la guía que aparece bajo cada palabra en inglés, usando tu acento habitual.
      </p>
      <p>
        En este nivel, todas las letras se pronuncian como en español, con una excepción:
        la introducción de la letra W. Así que, cuando encuentres la letra W, esto es lo que
        significa:
      </p>
      <p className="current-level">{ nivel1desc }</p>
      <hr />
      <p>Ahora, ¡ingresa un texto en inglés abajo para traducirlo!</p>
    </div>
  );
}

export default TabInfo1;
