import React from 'react';
import {
  nivel1desc, nivel2desc, nivel3desc,
} from './Descriptions';

function TabInfo3() {
  return (
    <div className="tab-info">
      <h3>Nivel 3</h3>
      <p>
        Este nivel introduce el sonido SH. Este sonido no existe en la mayoría
        de los dialectos del español, pero es muy común en inglés. Los niveles
        anteriores escribieron cada sonido SH como CH, pero ahora, cuando veas
        <b> </b>
        <u>sh</u>
        <b> </b>
        en la pronunciación mostrada, esto es lo que significa:
      </p>
      <p className="current-level">{ nivel3desc }</p>
      <hr />
      Este nivel también incluye los cambios de los niveles anteriores:
      <p>{ nivel2desc }</p>
      <p>{ nivel1desc }</p>
    </div>
  );
}

export default TabInfo3;
